import { aemAxiosInstance } from "@/constants/api"
import { COMPONENT_TYPES } from "@/constants"
import { GLOBAL_CONFIGURATION_ENDPOINT } from "@/constants"
import { recursiveDataFunction } from "@/utils/index"

export const getPromoData = async link => {
  const finalData = aemAxiosInstance({
    url: GLOBAL_CONFIGURATION_ENDPOINT,
    params: {
      path: link + ".model.json",
    },
  })
    .then(res => {
      const dat = recursiveDataFunction(res?.data, COMPONENT_TYPES?.PROMOBANNER)
      return dat[0]
    })
    .catch(err => {
      console.log("Failed to get compare attributes JSON file", err)
    })
  return finalData
}
