import React, { useEffect, useRef, useState } from "react"
import {
  createIdFromString,
  nbspSanitizeInnerHtml,
  sanitizeText,
} from "@/utils/helper"
import { getShortenedUrl } from "@/utils/location"
import Button from "@/components/core/Button/Button"
import DynamicImage from "@/components/core/Image/DynamicImage"
import styles from "@/components/PromoBanner/v1/index.module.scss"
import { getPromoData } from "@/components/PromoBanner/v2/promoBannerHelper"
import { dynamicSearch } from "@/constants/index"

const PromoBanner = props => {
  const {
    setPromoData = () => {},
    xfLink = "",
    col = 0,
    position = 0,
    width = 0,
    promoJson = {},
    promoIndex = 0,
    parent = "",
    onFilter = () => {},
  } = props
  const [data, setData] = useState({
    imgUrl: "",
    desc: "",
    ctaText: "",
    ctaLink: "",
    position: "",
    promoBg: "",
    fontColor: "inherit",
  })
  const [dynamicId, setDynamicId] = useState("")
  const promoDesc = useRef(null)
  const buttonStyle = {
    color: data.fontColor,
    borderColor: data.fontColor,
  }
  const [actionLink, setActionLink] = useState("")

  useEffect(() => {
    getXfData()
  }, [xfLink, width])

  useEffect(() => {
    if (data?.desc) {
      const dynamicId = createIdFromString(data?.desc)
      setDynamicId(dynamicId)
    }
  }, [data?.desc])

  useEffect(() => {
    if (data?.ctaLink) {
      getShortenedUrl(data?.ctaLink).then(res => setActionLink(res))
    }
  }, [data])

  const setPromoJsonData = async (jsonData = {}) => {
    if (jsonData) {
      const shortenedCtaLink = await getShortenedUrl(jsonData.ctaLink ?? "")
      const result = {
        imgUrl: jsonData.imageUrl ?? "",
        desc: jsonData.title ?? "",
        altText: jsonData.isDecorativeTeaser !== "true" ? jsonData.altText : "",
        ctaText: jsonData.ctaLabel ?? "",
        ctaLink: shortenedCtaLink?.includes(".html")
          ? shortenedCtaLink
          : `${shortenedCtaLink}.html`,
        ctaAriaLabel: `${jsonData.ctaLabel ?? ""} ${sanitizeText(
          jsonData.title ?? ""
        )}`,
        assetPath: jsonData.assetPath ?? "",
        position: position,
        infoTextAlign:
          (width > 1023
            ? jsonData.contentPosition
            : jsonData.mobileContentPosition) ?? "dead-center",
        promoBg: getGradiant(jsonData),
        fontColor: jsonData.fontColor ?? "inherit",
        isNewTab: jsonData.isNewTab === "true",
        isBannerLink: jsonData.linkAll === "true",
        isSmartCrop: jsonData.presetType === "smartcrop",
      }
      setData(result)
      if (setPromoData) {
        setPromoData(result)
      }
    } else if (setPromoData) {
      setPromoData({ position: position })
    }
  }

  const getXfData = async () => {
    if (promoJson && Object.keys(promoJson).length > 0) {
      setPromoJsonData(promoJson)
    } else {
      const link = xfLink?.split(".html")[0]
      if (link) {
        try {
          const data = await getPromoData(link)
          setPromoJsonData(data)
        } catch (error) {
          console.error(error)
        }
      }
    }
  }

  const getGradiant = promoRes => {
    const gradientData =
      (width > 767) & (width < 1024)
        ? Array.isArray(promoRes.mobileGradientPosition)
          ? promoRes.mobileGradientPosition
          : [promoRes.mobileGradientPosition]
        : Array.isArray(promoRes.gradientPosition)
        ? promoRes.gradientPosition
        : [promoRes.gradientPosition]
    let gradientBg = ""
    let gradientPos = ""
    const toColor = `${promoRes.opacityColor}${Math.floor(
      ((width > 767 ? promoRes.gradientOpacity : promoRes.tintOverlayValue) /
        100) *
        255
    )
      .toString(16)
      .padStart(2, 0)}`
    if (width > 767) {
      gradientData?.forEach((pos, i) => {
        if (pos === "topToBottom") {
          gradientPos = "to bottom"
        } else if (pos === "bottomToTop") {
          gradientPos = "to top"
        } else if (pos === "leftToRight") {
          gradientPos = "to right"
        } else if (pos === "radialCenterOut") {
          gradientPos = "circle"
        }
        gradientBg =
          gradientBg +
          (i > 0 ? ", " : "") +
          `${
            pos === "radialCenterOut" ? "radial-gradient" : "linear-gradient"
          }(${gradientPos}, ${
            promoRes.opacityColor + "00"
          } 0%, ${toColor} 100%)`
      })
    } else {
      gradientBg = toColor
    }
    return gradientBg !== "" ? gradientBg : "rgba(0,0,0,0)"
  }

  const onBannerClick = event => {
    if (data.isBannerLink) {
      handlePromoBannerClick(event, data)
      window.open(data.ctaLink, data.isNewTab ? "_blank" : "")
    }
  }

  const ctatitle = data?.ctaText?.toLowerCase()

  let eventInfo
  if (window.location?.pathname?.includes("search")) {
    eventInfo = {
      eventAction: `search results:products:main content area:${ctatitle}`,
      eventName: `search results:products:main content area:${ctatitle}`,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: ctatitle,
      internalLinkPosition: "search",
      internalLinkType: "search results:internal campaign",
      internalLinkZoneName: "search results:products:main content area",
      internalLinkURL: window.location.origin + data.ctaLink,
      clickInternalLinks: "true",
    }
  } else if (parent?.toLowerCase() === dynamicSearch) {
    eventInfo = {
      eventAction: `search results:products:main content area:${ctatitle}`,
      eventName: `search results:products:main content area:${ctatitle}`,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: ctatitle,
      internalLinkPosition: "suggested search",
      internalLinkType: "suggested search results:internal campaign",
      internalLinkZoneName: "suggested search results:top products results",
      internalLinkURL: window.location.origin + data.ctaLink,
      clickInternalLinks: "true",
    }
  } else {
    eventInfo = {
      eventAction: `plp:${ctatitle}`,
      eventName: `plp:${ctatitle}`,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: ctatitle,
      internalLinkPosition: "plp",
      internalLinkType: "plp:internal campaign",
      internalLinkZoneName: "plp:promo tiles",
      internalLinkURL: window.location.origin + data.ctaLink,
      clickInternalLinks: "true",
    }
  }

  const scrollToTop = () => {
    const scrollStep = -window.scrollY / (1000 / 15) // scroll speed here
    const scrollInterval = setInterval(() => {
      if (window.scrollY !== 0) {
        window.scrollBy(0, scrollStep)
      } else {
        clearInterval(scrollInterval)
      }
    }, 15)
  }

  const handlePromoBannerClick = (event, data = 1) => {
    onFilter([])
    actionLink === window.location.pathname ? scrollToTop() : ""
    event.stopPropagation()
    if (window.adobeDataLayer) {
      const { adobeDataLayer: { getState } = {} } = window
      const page = (getState && getState("page")) || {}
      const componentInfo = {
        componentID: window.location.href,
        componentLocation: window.location.pathname.includes("search")
          ? "search results:promo tiles"
          : "plp:promo tiles",
        componentMessage: `promo-${promoIndex}`,
        componentName: data?.desc
          .replace(/<\/?[^>]+(>|$)/g, " ")
          .replace(/(\r\n|\n|\r)/gm, "")
          .replace(/&nbsp;/g, " ")
          ?.toLowerCase(),
        componentType: "internal campaign",
      }
      const eventDetails = {
        event: "cmp:click",
        eventInfo,
        componentInfo,
        page,
      }
      window.adobeDataLayer.push(eventDetails)
    }
  }

  return (
    <div className={`${styles.productPromoBanner}`}>
      <div
        className={`plp-promo-banner wow animate__fadeInUp ${
          col === 2 || col === 3 ? "--fixed-height" : ""
        }`}
      >
        <div
          role={data.isBannerLink ? "link" : ""}
          tabIndex={data.isBannerLink ? 0 : -1}
          onClick={onBannerClick}
          className={`plp-promo-banner__layout --col-${col ?? 1}`}
        >
          <div className="plp-promo-banner__image">
            <DynamicImage
              imgUrl={data?.imgUrl}
              alt={data?.desc}
              uniqueId={dynamicId}
            />
          </div>
          <div
            className="plp-promo-banner__info-bg"
            style={{ background: `${data.promoBg}` }}
          ></div>
          <div
            className={`plp-promo-banner__info plp-promo-banner__info-${data.infoTextAlign}`}
          >
            <div
              ref={promoDesc}
              className="plp-promo-banner__desc"
              style={{ color: `${data.fontColor ?? ""}` }}
            >
              {nbspSanitizeInnerHtml(data.desc)}
            </div>
            {actionLink !== "" &&
              (actionLink === window.location.pathname ? (
                <div className="plp-promo-banner__button">
                  <Button
                    role="button"
                    customClass="plp-promo-banner__cta"
                    type="ghost"
                    size="small"
                    label={data.ctaText}
                    ariaLabel={data.ctaAriaLabel}
                    onClick={event => handlePromoBannerClick(event, data)}
                    style={buttonStyle}
                    useDefaultClass={true}
                  />
                </div>
              ) : (
                <div className="plp-promo-banner__button">
                  <Button
                    role="link"
                    customClass="plp-promo-banner__cta"
                    type="ghost"
                    size="small"
                    to={actionLink}
                    label={data.ctaText}
                    ariaLabel={data.ctaAriaLabel}
                    onClick={event => handlePromoBannerClick(event, data)}
                    style={buttonStyle}
                    useDefaultClass={true}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PromoBanner
